<template>
  <div class="app">
    <Header/>
    <div class="app__content">
      <IOS v-if="isIos"/>
      <Chrome v-else/>
    </div>
  </div>
</template>

<script>
import Utils from "@/Utils";
import Chrome from "@/screen/Chrome";
import IOS from "@/screen/IOS";
import Header from "@/components/Header";

export default {
  components: {Chrome, IOS, Header},
  data(){
    return {
      isIos: Utils.isIos(),
    }
  },
}
</script>

<style lang="scss">
@import "@/scss/variables";

.app{
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;

  &__content{
    flex-grow: 1;
    display: flex;
    justify-content: center;
    padding: 20px 14px;
    align-items: center;
  }
}
</style>