<template>
  <div class="ios-page">
    <Kitten class="ios-page__logo"/>
    <div class="ios-page__text">
      Установить приложение Dorama.land просто!<br> Следуй инструкции:
    </div>
    <ol class="ios-page__list">
      <li v-if="!safari">Открой сайт Dorama.land в браузере<br><span class="ios-page__safari">Safari</span></li>
      <li>
        Кликни в строке меню<br class="show__mobile"> на иконку
        <svg class="ios-page__icon1" width="30" height="27" viewBox="0 0 30 27" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect opacity="0.4" x="0.5" y="0.5" width="28.5842" height="25.9722" rx="3.5" fill="#E79899" stroke="#E79899"/>
          <path d="M14.4046 5.13232C14.6186 4.94873 14.9655 4.94873 15.1794 5.13232L17.9187 7.48284C18.1327 7.66643 18.1327 7.96408 17.9187 8.14767C17.7047 8.33126 17.3579 8.33126 17.1439 8.14767L15.3399 6.59967L15.3399 14.3967C15.3399 14.6564 15.0946 14.8668 14.792 14.8668C14.4895 14.8668 14.2442 14.6564 14.2442 14.3967L14.2442 6.59967L12.4402 8.14767C12.2262 8.33126 11.8793 8.33126 11.6654 8.14767C11.4514 7.96408 11.4514 7.66643 11.6654 7.48284L14.4046 5.13232Z" fill="#FBE0D0"/>
          <path d="M8.76563 11.5761C8.76563 10.7972 9.50148 10.1658 10.4092 10.1658H11.3115C11.6141 10.1658 11.8594 9.95531 11.8594 9.69567C11.8594 9.43604 11.6141 9.22557 11.3115 9.22557H10.4092C8.89634 9.22557 7.66992 10.2779 7.66992 11.5761V18.6277C7.66992 19.9258 8.89634 20.9782 10.4092 20.9782H19.1749C20.6877 20.9782 21.9141 19.9258 21.9141 18.6277V11.5761C21.9141 10.2779 20.6877 9.22557 19.1749 9.22557H18.2725C17.97 9.22557 17.7247 9.43604 17.7247 9.69567C17.7247 9.95531 17.97 10.1658 18.2725 10.1658H19.1749C20.0826 10.1658 20.8184 10.7972 20.8184 11.5761V18.6277C20.8184 19.4066 20.0826 20.038 19.1749 20.038H10.4092C9.50148 20.038 8.76563 19.4066 8.76563 18.6277V11.5761Z" fill="#FBE0D0"/>
        </svg>
      </li>
      <li>
        Пролистай ниже, затем выбери
        <div class="ios-page__selection">На экран «Домой»</div>
      </li>
      <li>
        Готово! Приложение Dorama.land отобразится на домашнем экране твоего устройства
      </li>
    </ol>
    <Btn @click="back" class="ios-page__btn" inverse>
      Вернуться на сайт
    </Btn>
  </div>
</template>

<script>
import Kitten from "@/components/Kitten";
import Btn from "@/components/Btn";
import Utils from "@/Utils";
export default {
  name: "IOS",
  components: {Btn, Kitten},
  data(){
    return {
      safari: Utils.isSafari(),
    }
  },
  methods: {
    back(){
      window.location.href = '/redirect?back=1';
    }
  }
}
</script>

<style lang="scss">
@import "@/scss/variables";

.ios-page{
  display: flex;
  align-items: center;
  flex-direction: column;

  &__logo{
    width: 219px;
  }

  &__text{
    font-size: 24px;
    font-weight: 500;
    line-height: 30px;
    text-align: center;
    margin-top: 23px;
  }

  &__list{
    list-style-type: decimal;
    display: inline-flex;
    flex-direction: column;
    margin-top: 21px;
    margin-left: 25px;
    max-width: 380px;

    li + li{
      margin-top: 12px;
    }

    li{
      line-height: 26px;
      font-size: 20px;
      font-weight: 400;
    }
  }

  &__icon1{
    position: relative;
    top: -1px;
    margin-bottom: -10px;
    margin-left: 4px;
  }

  &__selection{
    position: relative;
    height: 26px;
    margin-top: 1px;
    padding: 0 8px;
    display: inline-flex;
    align-items: center;
    margin-bottom: -5px;

    &:before{
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      border-radius: 4px;
      opacity: 0.4;
      background: $color-secondary-bg;
    }
  }

  &__safari{
    color: $color-secondary-bg;
  }

  &__btn{
    margin-top: 30px;
    max-width: 423px;
  }

  @include mobile{
    &__logo{
      width: 180px;
    }

    &__text{
      margin-top: 19px;
    }

    &__list{
      margin-left: 30px;
    }

    &__btn{
      margin-top: 19px;
    }
  }
}
</style>