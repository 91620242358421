<template>
  <div class="chrome-page">
    <Kitten :success="success" class="chrome-page__logo"/>
    <div v-if="success" class="chrome-page__success">
      <SuccessIcon class="chrome-page__success-icon"/> Приложение успешно<br class="show__mobile"/> установлено!<br/>
      Оно добавлено на <span class="show__desktop">рабочий стол и в меню<br/></span><span class="show__mobile">домашний<br/> экран</span> твоего устройства.
    </div>
    <div class="chrome-page__bottom">
      <div class="chrome-page__already-installed" v-if="isInstalled">
        <div>🤔 Приложение уже установлено<br> на устройстве или удалено<br> не полностью (снесена только иконка).</div>
        <div>👉🏻 Рекомендуем поискать<br> на рабочем столе<br> или в остаточных файлах.</div>
      </div>
      <Btn @click="doInstall" class="chrome-page__btn" v-if="!success && prompt && !isInstalled">
        <template v-slot:icon>
          <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.491979 12.5261C-0.221997 16.6479 -1.03971 21.3687 4.96803 19.6293C13.1274 17.267 18.2788 13.4682 17.9883 10.2691C17.6979 7.06997 5.49639 -0.493232 2.07972 0.0253875C-0.538704 0.422841 -0.0610338 2.99537 0.471809 5.86504C0.642476 6.78418 0.818804 7.73381 0.90087 8.65221C0.990075 9.6505 0.74768 11.0499 0.491979 12.5261ZM9.11028 8.62845C7.97582 11.2451 11.123 12.1251 12.1944 11.5449C14.18 10.4698 12.2879 7.99361 11.8072 7.55726C11.7276 7.48506 11.5878 7.32932 11.4042 7.12472C10.4781 6.09293 8.43663 3.81834 7.37744 4.74496C6.97762 5.09473 7.36745 5.36851 7.90015 5.74263C8.68748 6.29558 9.78693 7.06773 9.11028 8.62845ZM3.909 5.73489C3.67922 6.15522 3.50161 6.65326 3.82486 7.16449C4.01659 7.46772 4.37323 7.54128 4.79781 7.62885C5.08905 7.68892 5.41225 7.75559 5.73612 7.9075C5.92994 7.99841 6.07235 8.13713 6.19708 8.25863C6.42808 8.48365 6.59842 8.64958 6.92239 8.34326C7.67932 7.62754 6.78421 6.11466 6.06146 5.49216C5.39537 4.91846 4.45847 4.72974 3.909 5.73489ZM4.30079 15.9636C3.93385 15.5408 3.79021 14.7641 3.87579 13.9509C3.91862 13.5438 3.95991 13.2178 3.99458 12.944C4.08098 12.2617 4.12633 11.9036 4.05228 11.4242C3.58194 8.38003 6.80602 9.60346 8.22624 12.064C8.59345 12.7002 9.36844 12.7768 10.0403 12.8432C10.6946 12.9079 11.251 12.9629 11.2375 13.5157C11.2087 14.6965 9.62658 15.0112 8.30506 15.274C7.69588 15.3952 7.14208 15.5054 6.82134 15.6842C5.80481 16.2512 4.91941 16.6763 4.30079 15.9636Z" fill="#FBE0D0"/>
          </svg>
        </template>
        установить приложение
      </Btn>
      <div class="chrome-page__return" v-if="success">
        Возврат к предыдущей<br> странице через {{sec}}
      </div>
    </div>
  </div>
</template>

<script>
import Kitten from "@/components/Kitten";
import Btn from "@/components/Btn";
import SuccessIcon from "@/components/SuccessIcon";
import Utils from "@/Utils";

export default {
  name: "Chrome",
  components: {SuccessIcon, Btn, Kitten},
  data(){
    return {
      isInstalled: false,
      success: false,
      prompt: null,
      sec: 3,
      inPwa: false,
    }
  },
  async created() {
    Utils.isAppInstalled().then(e => {
      this.isInstalled = e;
    });

    this.prompt = await Utils.PWAInstallPrompt();
  },
  methods: {
    async doInstall(){
      window.ym(95670136,'reachGoal','click');

      try{
        await this.prompt();
      }catch (e){
        this.prompt = await Utils.PWAInstallPrompt();
        return;
      }
      this.success = true;

      setInterval(() => {
        const a = document.createElement('A');
        a.href = "/redirect";
        document.body.appendChild(a);
        a.click();
      }, 5000);

      let i = setInterval(() => {
        this.sec--;

        if(!this.sec){
          clearInterval(i);
          window.location.href = '/redirect?back=1';
        }
      }, 1000);
    },
  }
}
</script>
<style lang="scss">
@import "@/scss/variables";

.chrome-page{
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 636px;
  width: 100%;

  &__logo{
    width: 250px;
  }

  &__success{
    text-align: center;
    font-size: 30px;
    font-weight: 500;
    line-height: 38px;
    margin-top: 27px;
  }

  &__success-icon{
    display: inline-flex;
    position: relative;
    width: 29px;
    margin: 0 0 -2px -7px;
    height: auto;
  }

  &__btn{
    margin-top: 43px;
    max-width: 434px;
  }

  &__bottom{
    width: 100%;
    display: flex;
    justify-content: center;
    min-height: 85px;
  }


  &__return{
    color: $color-text2;
    text-align: center;
    font-size: 18px;
    font-weight: 300;
    line-height: 26px;
    margin-top: 32px;
  }

  &__already-installed{
    display: flex;
    flex-direction: column;
    align-items: center;

    > div{
      text-align: center;
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 30px;
      line-height: 38px;
      position: relative;


      &:first-child{
        max-width: 684px;
        margin: 40px -20px 0;
      }

      &:last-child{
        max-width: 584px;
        margin-top: 40px;
      }
    }

    @include desktop{
      br{
        display: none;
      }
    }
  }

  @include mobile{
    &__btn{
      max-width: 332px;
    }

    &__success{
      font-size: 22px;
      line-height: 30px;
      margin-top: 33px;
    }

    &__success-icon{
      width: 21px;
      height: auto;
      margin: 0 0 -2px 0;
    }

    &__already-installed{
      > div{
        font-size: 22px;
        line-height: 30px;

        &:first-child{
          margin-right: 0;
          margin-left: 0;
          max-width: 332px;
        }

        &:last-child{
          max-width: 332px;
          margin-top: 30px;
        }
      }
    }
  }
}
</style>